<template>
  <b-row>
    <b-col>
      <div class="not-found-wrap text-center">
        <h1 class="text-60">
          Oops...
        </h1>
        <p class="text-36 subheading mb-3">{{$route.query.error}}</p>
        <p class="mb-5  text-muted text-18">
          {{$route.query.error_description}}
        </p>
        <p>
          U wordt doorverwezen.
        </p>
        <a class="btn btn-lg btn-primary btn-rounded" href="/"
          >Go back to home</a
        >
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Access error"
  },
  mounted() {
    setTimeout(() => {
      this.$auth.logout();
    }, 4000)
  }
};
</script>
